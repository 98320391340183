const MUser = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'merchant.dashboard'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fad fa-chart-tree-map',
    active_icon: 'far fa-chart-tree-map'
  },
  {
    title: 'Reports',
    is_heading: true,
    is_active: false,
    link: {
      name: 'merchant.reports'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Disbursements',
    is_heading: false,
    is_active: false,
    link: {
      name: 'merchant.reports.disbursements'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fad fa-money-check-dollar-pen',
    active_icon: 'far fa-money-check-dollar-pen'
  },
  {
    title: 'Replenishments',
    is_heading: false,
    is_active: false,
    link: {
      name: 'merchant.reports.replenishments'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fad fa-hand-holding-dollar',
    active_icon: 'far fa-hand-holding-dollar'
  }
]

export default MUser
