<template>
  <div class="iq-footer">
    <footer class="px-0 py-2">
      <div class="container-fluid">
        <div class="d-flex flex-column flex-md-row  justify-content-md-between">
          <div>
            Copyright 2024 - All Rights Reserved.
          </div>
          <div>
            <a
              href="https://google.com"
              target="company-website"
            >
              Company Name
            </a>
          </div>
        </div>
      </div>
    </footer>
    <!-- <FloatingButton /> -->
  </div>
</template>
<script>
// import FloatingButton from './FloatingButton.vue'
export default {
  name: 'Footer'
}
</script>
