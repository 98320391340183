const MAdmin = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fad fa-chart-tree-map',
    active_icon: 'far fa-chart-tree-map'
  },
  {
    title: 'Disbursements',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.disbursements'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fad fa-money-check-dollar-pen',
    active_icon: 'far fa-money-check-dollar-pen'
  },
  {
    title: 'Accounts',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.accounts'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Mechants',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.accounts.merchants'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fad fa-user-lock',
    active_icon: 'far fa-user-lock'
  },
  // {
  //   title: 'Merchants',
  //   is_heading: false,
  //   is_active: false,
  //   link: {
  //     name: 'admin.accounts.merchants'
  //   },
  //   class_name: 'mb-3',
  //   is_icon_class: true,
  //   icon: 'fad fa-users',
  //   active_icon: 'far fa-users'
  // },
  {
    title: 'Webhooks',
    is_heading: true,
    is_active: false,
    link: {
      name: 'admin.webhooks'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Calls',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.webhooks.calls'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fad fa-rotate',
    active_icon: 'far fa-rotate '
  },
  {
    title: 'Events',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.webhooks.events'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fad fa-list-tree',
    active_icon: 'far fa-list-tree'
  },
  {
    title: 'Logs',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.webhooks.logs'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'fad fa-list',
    active_icon: 'far fa-list'
  }
]

export default MAdmin
